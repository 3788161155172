export const getUserEnv = (window) => {
  return {
    os: getOperatingSystem(window),
    browser: getBrowser(window)
  };
};

const getOperatingSystem = (window) => {
  let operatingSystem = 'Not known';
  switch (true) {
    case window.navigator.appVersion.indexOf('Win') !== -1:
      operatingSystem = 'win';
      break;
    case window.navigator.appVersion.indexOf('Mac') !== -1:
      operatingSystem = 'mac';
      break;
    case window.navigator.appVersion.indexOf('X11') !== -1:
      operatingSystem = 'unix';
      break;
    case window.navigator.appVersion.indexOf('Linux') !== -1:
      operatingSystem = 'linux';
      break;
  }

  return operatingSystem;
};

const getBrowser = (window) => {
  switch (true) {
    case window.navigator.userAgent.toLowerCase().indexOf('edge') > -1:
      return 'edge';
    case window.navigator.userAgent.toLowerCase().indexOf('edg/') > -1:
      return 'edge';
    case window.navigator.userAgent.toLowerCase().indexOf('opr') > -1 && !!window.opr:
      return 'opera';
    case (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && !!window.chrome) ||
      (window.navigator.userAgent.toLowerCase().indexOf('safari') > -1 &&
        window.navigator.userAgent.toLowerCase().indexOf('mobile') > -1):
      return 'chrome';
    case window.navigator.userAgent.toLowerCase().indexOf('trident') > -1:
      return 'msie';
    case window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1:
      return 'ff';
    case window.navigator.userAgent.toLowerCase().indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
};
