export const REALM_CONFIGURATIONS: Map<string, any[]> = new Map([
  [
    'local-development',
    [
      {
        domain: 'trainhq',
        realm: 'trainhq',
        clientId: 'trainhq-learner-app',
        // url: 'http://localhost:8080/',
        url: 'https://auth.dev.trainhq.com/',
        resource: 'trainhq-learner-app'
      },
      {
        domain: 'rp',
        realm: 'rp',
        clientId: 'trainhq-learner-app',
        url: 'https://auth.dev.trainhq.com/'
      },
      {
        domain: 'qa',
        realm: 'qa',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://auth.dev.trainhq.com/'
      }
    ]
  ],
  [
    'dev',
    [
      {
        domain: 'trainhq',
        realm: 'trainhq',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://auth.dev.trainhq.com/'
      },
      {
        domain: 'qa',
        realm: 'qa',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://auth.dev.trainhq.com/'
      },
      {
        domain: 'rp',
        realm: 'rp',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://auth.dev.trainhq.com/'
      }
    ]
  ],
  [
    'staging',
    [
      {
        domain: 'trainhq',
        realm: 'trainhq',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.staging.trainhq.com/'
      },
      {
        domain: 'pentest',
        realm: 'pentest',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.staging.trainhq.com/'
      },
      {
        idpHint: 'azureoidc',
        domain: 'buzzeasy',
        realm: 'buzzeasy',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.staging.trainhq.com/'
      }
    ]
  ],
  [
    'production',
    [
      {
        domain: 'trainhq',
        realm: 'trainhq',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'eastridge',
        realm: 'eastridge',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'transporeon',
        realm: 'transporeon',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'echo',
        realm: 'echo',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'climatepros',
        realm: 'climatepros',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'metroplus',
        realm: 'metroplus',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'iboss',
        realm: 'iboss',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'qa',
        realm: 'qa',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'servicetitan',
        realm: 'servicetitan',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'drakes',
        realm: 'drakes',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'geomant',
        realm: 'geomant',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'gartner',
        realm: 'gartner',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'bayada',
        realm: 'bayada',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'carters',
        realm: 'carters',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'hubspot',
        realm: 'hubspot',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'gm',
        realm: 'gm',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'bsn',
        realm: 'bsn',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'mobileone',
        realm: 'mobileone',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      },
      {
        domain: 'novum',
        realm: 'novum',
        clientId: 'trainhq-learner-app',
        resource: 'trainhq-learner-app',
        url: 'https://id.trainhq.com/'
      }
    ]
  ]
]);
