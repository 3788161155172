import { REALM_CONFIGURATIONS } from '@/realmConfig/realm-config';
const PROFILE_MODE = import.meta.env.MODE;

const supportedDomains = [
  'trainhq',
  'eastridge',
  'climatepros',
  'echo',
  'iboss',
  'metroplus',
  'transporeon',
  'qa',
  'rp',
  'pentest',
  'servicetitan',
  'drakes',
  'geomant',
  'gartner',
  'bayada',
  'buzzeasy',
  'carters',
  'hubspot',
  'gm',
  'bsn',
  'mobileone',
  'novum'
];

export function getSupportedDomain() {
  let domainToCheck = window.location.host;
  if (domainToCheck.includes('://')) {
    domainToCheck = domainToCheck.replace('https://', '').replace('http://', '');
  }
  if (domainToCheck.startsWith('www.')) {
    domainToCheck = domainToCheck.replace('www.', '');
  }
  const subdomain = domainToCheck.split('.')[0];
  console.log('Domain detected: ', subdomain);
  if (supportedDomains.includes(subdomain)) {
    console.log('Found supported domain: ', subdomain);
    return subdomain;
  }
  console.log('Supported domain not found: ', subdomain, ' Fallback to trainhq.');

  return 'trainhq';
}

export function getRealmConfig() {
  const realmId = getSupportedDomain();
  const profileRealmConfigurations = REALM_CONFIGURATIONS.get(PROFILE_MODE);
  return profileRealmConfigurations.find((realmConfig) => realmConfig.domain === realmId);
}
