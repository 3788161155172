import React, { createContext, useCallback, useContext, useState } from 'react';

import { Typography } from '@mui/material';
import {
  THQDialog,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  THQPrimaryButton
} from '@trainhq/trainhq-client-core';

type CallTimeoutDialogContext = (onRestartCallback: () => void) => void;
const context = createContext<CallTimeoutDialogContext>(null);
export const useCallTimeoutDialog = () => useContext(context);

interface CallTimeoutDialogProps {
  children?: React.ReactNode;
  onRestart(callback?: () => void): void;
  onSubmit(): void;
}

const CallTimeoutDialog: React.FC<CallTimeoutDialogProps> = ({ children, onRestart, onSubmit }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [onRestartCallback, setOnRestartCallback] = useState<() => void>();

  const handleOnRestart = useCallback(() => {
    setOpen(false);
    onRestart(onRestartCallback);
  }, [onRestart, onRestartCallback]);

  const contextValue = useCallback<CallTimeoutDialogContext>((onRestartCallback: () => void) => {
    setOpen(true);
    setOnRestartCallback(() => onRestartCallback);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} showXButton={false}>
        <THQDialogTitle>...are you still there? 👀</THQDialogTitle>
        <THQDialogContent>
          <Typography>
            It looks like you’ve been quiet for a while, so unfortunately we had to stop the role-play. Would you like
            to submit to get an evaluation, or start again from the top?
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <THQPrimaryButton onClick={handleOnRestart}>Start from the top</THQPrimaryButton>
          <THQPrimaryButton variant="main" onClick={onSubmit}>
            Submit role-play
          </THQPrimaryButton>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default CallTimeoutDialog;
